/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.info-row {
  display: flex;
  margin-bottom: 15px;
}
.info-row .info-col {
  display: flex;
  margin-left: 30px;
}
.info-row .info-col:first-child {
  margin-left: 0;
}
.info-row .info-col:first-child .info-col-label {
  width: 100px;
  text-align: right;
}
.info-row .info-col .info-col-content {
  margin-left: 5px;
}
.attendance-table td {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding: 2px 8px;
}
